.h1, .h2, 
.h3, .h4, 
.body_big, 
.body_small, 
.tags {
	color: #001F19;
}

.h1, .h2, 
.h3, .h4 {
	font-family: Roca One;
}

.body_big, 
.body_small, 
.tags {
	font-family: NT Bau;
}

.h1 {
  font-weight: 400;
  font-size: 54px;
	line-height: 100%;
	letter-spacing: 1.08px;
}

.h2 {
  font-weight: 400;
  font-size: 45px;
	line-height: 110%;
	letter-spacing: 0.9px;
}

.h3 {
  font-weight: 400;
  font-size: 34px;
	line-height: 120%;
	letter-spacing: 0.68px;
}

.h4 {
  font-weight: 400;
  font-size: 24px;
	line-height: 120%;
	letter-spacing: 0.48px;
}

.body_big {
	font-family: NT Bau;
  font-weight: 400;
  font-size: 20px;
	line-height: 130%;
	letter-spacing: -0.4px;
}

.body_small {
	font-family: NT Bau;
  font-size: 15px;
	font-style: normal;
	font-weight: 400;
	line-height: 130%;
	letter-spacing: -0.3px;
}

.body_extra_small {
	font-family: NT Bau;
  font-size: 13px;
	font-style: normal;
	font-weight: 400;
	line-height: 130%;
	letter-spacing: -0.3px;
}

.tags {
	font-family: NT Bau;
	font-size: 12px;
	line-height: 130%;
	letter-spacing: 0.24px;
	text-transform: uppercase;
}

.bold {
  font-weight: 700;
}

.mb_16 {
  margin-bottom: 16px;
}

@media only screen and (max-width: 768px) {
  .h1 {
		font-size: 34px;
		line-height: 110%; 
		letter-spacing: 0.68px;
  }
  
	.h2 {
		font-size: 30px;
		line-height: 110%;
		letter-spacing: 0.6px;
  }
  
	.h3 {
		font-size: 26px;
		line-height: 120%;
		letter-spacing: 0.52px;
  }
  
	.h4 {
		font-size: 20px;
		line-height: 120%;
		letter-spacing: 0.4px;
  }

	.body_big {
		font-size: 18px;
		letter-spacing: -0.36px;
	}

	.body_small {
		font-size: 14px;
		letter-spacing: -0.28px;
	}

	.body_extra_small {
		font-size: 12px;
		letter-spacing: -0.28px;
	}

	.tags {
		font-size: 9px;
		letter-spacing: 0.18px;
	}
}
