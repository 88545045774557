.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  padding-top: 64px;

	background-color: #468EFF;

  .container {
    max-width: 1276px;
    width: 100%;

    .information {
      padding-bottom: 16px;
    }
    .suscription {
			display: flex;
			flex-direction: row;
			justify-content: center;
			gap: 15px;

			div {
				.input {
					border-radius: 30px;
					height: 60px;
					padding: 16px 34px;
					width: 453px;
					font-family: NT Bau;
					max-width: 100%;
				}
				.input::-webkit-input-placeholder {
					font-family: NT Bau;
				}
			}
    }

		.toast {
			width: 534px;
			min-height: 42px;
			margin: 0 auto;
			display: flex;
			justify-content: flex-start;
		}

    .columns {
      display: grid;
			grid-template-columns: 427px 314px 316px;
			gap: 135px;
			
			.about {
				
			}
			.courses {
				width: 314px;
				a {
					text-decoration: underline;
				}

				.links {
					margin-bottom: 12px;
				}
			}

			.courses, .social {
				margin-left: auto;
			}

			.social {
				width: 316px;

				.icons {
					display: flex;
					gap: 10px;
				}
			}
    }

		.extra {
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 40px;
		}
  }
}

@media only screen and (max-width: 768px) {
  .wrapper {
    padding: 32px 20px;

    .container {
      .information {
        padding-bottom: 34px;
      }
			.suscription {
				padding-bottom: 0;
				div {
					.input {
						width: 100%;
						max-width: 100%;
					}
				}
			}

      .social {
				margin-left: 0;
				display: flex;
      }

			.toast {
				width: 100%;
			}

			.columns {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				flex-wrap: nowrap;
				gap: 32px;

				.about {
					margin: 0 auto;
					.links {
						text-align: center;
					}
				}

				.courses {
					margin: 40px auto 0;
					
					p {
						text-align: center;
					}
				}
				.social {
					width: auto;
					display: flex;
					gap: 40px;

					margin: 0 0 56px;
					align-items: center;

					h4 {
						margin-bottom: 0 !important;
						font-size: 24px;
						font-family: Roca One;
						font-weight: 400;
						line-height: 120%;
						letter-spacing: 2%;
					}
				}
			}

			.extra {
				gap: 12px;
				margin-top: 0;

				span {
					font-size: 14px;
					font-family: NT Bau;
					font-weight: 400;
					line-height: 130%;
					letter-spacing: -2%;
				}
			}
    }
  }
}
