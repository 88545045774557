@import '../../global.module.scss';

// Black
$black: $black;
$dark: #001F19;
$black-hover: #555555;

// White
$white: $white;
$white-hover: #555555;

// Popup
$background-popup: $white;
$color-popup: #4c37e1;
$color-orange: #FFAA7D;
$color-orange-light: #FFEFE7;
$color-skyblue: #AAE2FF;
$color-skyblue-light: #E8F7FF;
$color-lila: #DEA8FF;
$color-lila-light: #F9EEFF;
$color-aqua: $aqua;
$color-aqua-light: #D9FFFB;

// Transparent
$background-transparent: transparent;
$color-transparent: $black;

.button {
  display: flex;
  justify-content: center;
  gap: 8px;
  align-items: center;
  border-radius: 25px;
  cursor: pointer;
  transition: all 0.25s ease;

  width: 100%;
  height: 56px;
  overflow: hidden;
	
	&:focus {
		outline: none;
	}
}

// ---------------
// VARIANTS
// ---------------

// Black
.black {
  background: $black;
  color: $white;

  @media (hover: hover) {
    &:hover {
      background: $black-hover;
    }
  }
}

// White
.white {
  background: $white;
  color: $black;

  @media (hover: hover) {
    &:hover {
      background: $white-hover;
      color: $white;
    }
  }
}

// Popup
.popup {
  background: $white;
  color: $color-popup;

  @media (hover: hover) {
    &:hover {
      background: $black;
      color: $white;
    }
  }
}

// lila
.lila {
  background: $color-lila;
  color: $dark;

  @media (hover: hover) {
    &:hover {
      background: $color-lila-light;
      color: $dark;
    }
  }
}

// aqua
.aqua {
  background: $color-aqua;
  color: $dark;

  @media (hover: hover) {
    &:hover {
      background: $color-aqua-light;
      color: $dark;
    }
  }
}

// orange
.orange {
  background: $color-orange;
  color: $dark;

  @media (hover: hover) {
    &:hover {
      background: $color-orange-light;
      color: $dark;
    }
  }
}

// dark
.dark {
  background: $dark;
  color: $color-aqua;

  @media (hover: hover) {
    &:hover {
      background: $dark-light;
    }
  }
}

// buttonArrow
.buttonArrow {
  width: 25px;
  height: 25px;
	border-radius: 50%;

  @media (hover: hover) {
    &:hover {
      background: $color-aqua-light;
      color: $dark;
    }
  }
}

// icon dark
.icon_dark {
  background: $dark;
  color: $white;

  @media (hover: hover) {
    &:hover {
      background: $dark-light;
      color: $white;
    }
    
  }
}

// icon aqua
.icon_aqua {
  background: $color-aqua;
  color: $dark;

  @media (hover: hover) {
    &:hover {
      background: $color-aqua-light;
      color: $dark;
    }
  }
}

// icon lila
.icon_lila {
  background: $color-lila;
  color: $dark;

  @media (hover: hover) {
    &:hover {
      background: $color-lila-light;
      color: $dark;
    }
  }
}

// icon skyblue
.icon_skyblue {
  background: $color-skyblue;
  color: $dark;

  @media (hover: hover) {
    &:hover {
      background: $color-skyblue-light;
      color: $dark;
    }
  }
}

// icon big aqua
.iconbig_aqua {
	width: 59px;
  height: 59px;
  background: $color-aqua;
  color: $dark;
	display: flex;
	align-items: center;
	justify-content: center;
	outline: none;
	& svg {
		transform: scale(1.9);
	}

  @media (hover: hover) {
    &:hover {
      background: $color-aqua-light;
      color: $dark;
    }
  }
}

// icon big lila
.iconbig_lila {
	width: 59px;
  height: 59px;
  background: $color-lila;
  color: $dark;
	display: flex;
	align-items: center;
	justify-content: center;
	outline: none;
	& svg {
		transform: scale(1.9);
	}

  @media (hover: hover) {
    &:hover {
      background: $color-lila-light;
      color: $dark;
    }
  }
}

// icon big skyblue
.iconbig_skyblue {
	width: 59px;
  height: 59px;
  background: $color-skyblue;
  color: $dark;
	display: flex;
	align-items: center;
	justify-content: center;
	outline: none;
	& svg {
		transform: scale(1.9);
	}

  @media (hover: hover) {
    &:hover {
      background: $color-skyblue-light;
      color: $dark;
    }
  }
}

// White Outline
.white-outline {
  background: $background-transparent;
  color: #ffffff;
  border: 2px solid #ffffff;

  @media (hover: hover) {
    &:hover {
      background: $background-transparent;
    }
  }
}

// Black Outline
.black-outline {
  background: $background-transparent;
  color: $dark;
  border: 1px solid $dark;

  @media (hover: hover) {
    &:hover {
      background: $background-transparent;
    }
  }
}

// Transparent
.transparent {
  background: $background-transparent;
  color: $color-transparent;
  justify-content: left;

  @media (hover: hover) {
    &:hover {
      background: $background-transparent;
    }
		
		&:hover .icon_skyblue {
			background: $color-skyblue-light;
			color: $white;
		}

		&:hover .icon_lila {
			background: $color-lila-light;
      color: $white;
		}

		&:hover .icon_aqua {
			background: $color-aqua-light;
      color: $white;
		}
  }
}

// Outline
.outline {
  background: $background-transparent;
  color: $color-transparent;
  border: 2px solid #000000;

  @media (hover: hover) {
    &:hover {
      background: $background-transparent;
    }
  }
}

// Contact
.contact {
  background: #dea8ff;
  color: black;
  border: none;
  border-radius: 25px;

  @media (hover: hover) {
    &:hover {
      background: $background-transparent;
      color: white;
    }
  }
}
