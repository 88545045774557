.wrapper {
	position: relative;
  padding: 56px 120px;
  background: #E2F2FF;

  .container {
    display: grid;
		grid-template-columns: 1fr minmax(539px, 1fr);
		align-items: center;
    gap: 82px;
		max-width: 1197px;
		margin: 0 auto;
		
    .text {
			display: flex;
			flex-direction: column;
			gap: 8px;
			
      .title {
				font-size: 34px;
				font-style: normal;
				font-weight: 400;
				line-height: 34px; /* 100% */
				letter-spacing: 1px;
			}
			.subtitle {
				font-size: 24px;
				color: #468EFF;
			}
    }

		.imageCard {
			width: 100%;
			height: 320px;
			border-radius: 24px;
			overflow: hidden;
			display: flex;
			align-items: center;
			
			& img {
				width: 100%;
			}
		}
		

  }
	.icon {
		position: absolute;
		bottom: -73px;
		left: 41px;
	}
}

@media only screen and (max-width: 768px) {
  .wrapper {
    padding: 40px 20px 64px;
    height: auto;

    .container {
      display: flex;
      flex-direction: column;
			gap: 32px;

			.text {

				.title {
					font-size: 30px;
					line-height: 110%;
					letter-spacing: 0.6px;
				}
	
				.subtitle {
					font-size: 18px;
				}
			}

			.imageCard {
				width: 100%;
				height: auto;
				border-radius: 24px;
				overflow: hidden;
				
				& img {
					width: 100%;
				}
			}
    }

		.icon {
			width: 62px;
			height: 74px;
			position: absolute;
			bottom: -38px;
			left: 30px;
		}
  }
}
